/*--------------------------------------------------------------
# Modal
--------------------------------------------------------------*/
  .modal-title{
    font-size: 18px;
    font-weight: 600;
  }
  .mod-custom,
  .mod-custom .modal-dialog{
    z-index: 9999;
  }
  .mod-custom form .scroll-div{
    max-height: 75vh;
    overflow-x: auto;
  }
  .mod-custom .required{
    color: red;
  }
  .mod-custom .modal-title{
    font-size: 18px;
    font-weight: 600;
  }
  .mod-custom .modal-header{
    padding: 8px 12px;
  }
  .mod-custom .form-label,
  .filter-custom .form-label{
    font-size: 15px;
    margin-bottom: 0px;
    color: var(--theme-blue);
    line-height: 1.5;
    font-weight: 600;
  }
  /* .mod-custom .form-control{
    border: 1px solid black;
  } */
  .mod-custom fieldset,
  .filter-custom fieldset{
    border: 1px solid #8ea9d5 !important;
    /* margin: 25px 0px; */
    background-color: #f0f2f4;
    border-radius: 3px;
    padding: 29px 18px 18px;
    margin-top: 12px;
    position: relative;
  }
  .mod-custom legend{
    font-size: 15px;
    font-weight: 600;
    border: 0;
    background: #fff;
    width: auto;
    transform: translateY(-50%);
    position: absolute;
    top: 0; left: 1em;
    padding: 0 .5em;
  }
  .mod-custom .form-control::placeholder{
    font-size: 14px !important;
    color: #919191 !important;
  }
  .mod-custom embed,
  .mod-custom img,
  .mod-custom object {
    height: 115px;
    width: 115px;
}
.cropper-preview-img {
  height: 200px !important;
  width: 200px !important;
}
/*--------------------------------------------------------------
# Filter Form
--------------------------------------------------------------*/
.reset-btn,
.reset-btn:hover{
  border: none;
}
.reset-btn:hover{
  background-color: transparent;
  color: #03A9F4;
}
.filter-form .form-floating>.form-control,
.filter-form .form-floating>.form-select {
  height: calc(2.5rem + 2px);
  line-height: 1;
}
.filter-form .form-floating>label {
  padding: 0.6rem .75rem;
  color: #919191;
  white-space: nowrap;
  /* width: -webkit-fill-available; */
  text-overflow: ellipsis;
  overflow: hidden;
}
.filter-form .filter-excel .form-floating>.form-control {
  height: calc(1.9rem + 2px);
}
.filter-form .filter-excel .form-floating>label {
  padding: 0.5rem .75rem;
}
.filter-float .accordion-header button{
  padding: 8px;
}
.filter-float fieldset{
  padding: 5px;
}
.filter-form .filter-excel button p{
  width: max-content;
  height: 15px;
  line-height: 14px;
}
.filter-float .loader-filter{
  width: 15px !important;
  height: 15px !important;
}
.table-master .scroll-td{
  height: 64px;
  overflow-y: scroll;
}
.filter-float .sub-select{
  /* min-width: 80px; */
  min-width: 65px;
}
.filter-float .sub-parent{
  /* width: calc(100% - 80px); */
  width: calc(100% - 65px);
}
.filter-float .sub-select .css-1yx1wj6-singleValue{
  font-size: 10px;
}
.filter-table{
  table-layout: fixed;
  width: 100%;
}
.filter-table .css-tlfecz-indicatorContainer{
  padding: 0;
}
.filter-table th{
  width: 10%;
}
.filter-table th,
.filter-table td{
  vertical-align: baseline !important;
  padding-right: 3px;
  padding-left: 3px;
}