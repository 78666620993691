.error-area {
    height: 60vh;
    text-align: center;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
}
.error-area .error-content h1 {
    font-size: 120px;
    color: #2042e3;
    margin-bottom: 10px;
    font-weight: 700;
}
.error-area .error-content h2 {
    font-size: 25px;
    margin-bottom: 10px;
    color: #081828;
}
.error-area .error-content p {
    font-weight: 500;
    margin-bottom: 20px;
}
.error-area .d-table{
    width: 100%;
    height: 100%;
}
.error-area .d-table-cell{
    vertical-align: middle;
}